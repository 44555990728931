<style lang="scss">
.contentteaser {
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }

    .bild-box {
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
  }
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .margin-auto {
    margin: 10px auto;
  }
  .plus-size {
    padding-top: 0 !important;
    height: 2px;
    padding-bottom: 0 !important;
    @media (max-width: 768px) {
      height: 8px;
    }
    strong {
      position: relative;
      top: -14px;
      left: 25px;
      font-size: 160%;
      display: inline-block;
      @media (max-width: 768px) {
        top: -11px;
      }
    }
  }
}
</style>

<template>
  <div class="content contentteaser container">
    <h1>Ihr Content-Bewerbungspaket</h1>
    <p class="intro-txt"><strong>Spannender Content?</strong> Damit Ihr Advertorial oder Ihr Themenspecial auch von der gewünschten Zielgruppe gefunden und gelesen wird, sorgen der Content-Teaser oder das Performance-Paket für reichlich Aufmerksamkeit.</p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content/prprodukte/d-content-teaser.jpg" alt="Content Teaser" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Content-Teaser</h2>
          <p>Mit der digitalen Bild-Text-Kombi machen Sie unsere User neugierig und sorgen für garantierte Sichtkontakte Ihres Inhaltes.</p>
          <div class="preis-box">
            <span>Tarif ab: <strong>30,00 TKP</strong></span>
          </div>
          <CollapsibleButton link="content-teaser" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Content-Teaser</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="content-teaser">
      <div class="row">
        <div class="col-lg-12">
          <h3>Produktdaten</h3>
          <p>Mit einem Content-Teaser erreichen Sie mit einer Buchung unsere User auf allen Endgeräten. Für maximale Reichweite kann Ihre Werbung dynamisch über alle Channels auf kleinezeitung.at ausgespielt werden (Run over Side – ROS). Für gezielte Ansprache von Usern können Sie auch einzelne Channels auswählen und Ihre Werbung nur dort ausspielen (Select).</p>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="tabelle-box">
            <div class="headline">
              Content-Teaser
            </div>
            <div class="row head-row ">
              <div class="col-lg-8 text-align-left hide-small">Produkt</div>
              <div class="col-lg-4 text-align-right hide-small">Tarif</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-8 col-8 text-align-left ">Content-Teaser / Sitelink <small>Größe: Dynamisch*</small></div>
              <div class="col-lg-4 col-4 text-align-right">ROS: 30,00</div>
              <div class="col-12 plus-size"><strong>+</strong></div>
              <div class="col-lg-8 col-8 text-align-left ">Mobile Content-Teaser / Sitelink <small>300 x 90 px</small></div>
              <div class="col-lg-4 col-4 text-align-right">Select: 40,00</div>
            </div>
            <div class="info-txt-tabelle">* Passt sich autoamtisch der Seitenbreite an.</div>
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content/prprodukte/bsp-content-teaser.png" alt="Bsp. Content Teaser" class="img-fluid d-block margin-auto" />
        </div>
      </div>
      <div class="info-txt-preis">Alle Preise verstehen sich als Tausenderkontaktpreis in Euro, exklusive 20 % Umsatzsteuer.</div>
    </Collapsible>
    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content/prprodukte/performance-paket.jpg" alt="Performance Paket" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Performance-Paket</h2>
          <p>Mit dem Performance-Paket sorgen Sie für Aufmerksamkeit und Ihr Themenspecial wird garantiert von unseren Usern gefunden.</p>
          <CollapsibleButton link="performance-paket" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Performance-Paket</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="performance-paket">
      <div class="row justify-content-center align-self-center">
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="tabelle-box">
            <div class="headline">
              Performance-Paket
            </div>
            <div class="row head-row ">
              <div class="col-lg-8 text-align-left hide-small">Leistung</div>
              <div class="col-lg-4 text-align-right hide-small">Tarif</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-8 col-8 text-align-left ">je 1000 Klicks auf das Themen-Special</div>
              <div class="col-lg-4 col-4 text-align-right">2.800,00</div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
    </Collapsible>
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/prprodukte/Content-Teaser-Performance-Paket-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif Content-Teaser / Performance-Paket
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>242 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'ContentTeaser',
  components: {
    CollapsibleButton,
    Collapsible,
  },
});
</script>
